<template>
    <div class="card card-custom">
        <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">{{ $t("settings.heading.basic") }}</h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("settings.subheading.basic") }}</span>
            </div>
            <div class="card-toolbar">
                <b-button class="btn btn-success mr-2" @click.prevent="saveBasicSettings" ref="save_submit">{{ $t("button.save") }}</b-button>
            </div>
        </div>
        <div class="card-body">
            <ValidationObserver ref="form">
              <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-right" for="project.name">{{ $t("settings.title.project_name") }}</label>
                    <div class="col-lg-9 col-xl-6">            
                        <ValidationProvider rules="required|min:3" v-slot="{ errors }">
                        <input 
                            name="project_name"  
                            id="project_name"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('settings.placeholder.project_name')"
                            v-model="settings.project_name"
                            >                
                            <span class="text-danger">{{ errors[0] }}</span>  
                        </ValidationProvider>    
                    </div>
                </div>
                 <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label text-right" for="project.url">{{ $t("settings.title.project_url") }}</label>
                    <div class="col-lg-9 col-xl-6">            
                        <ValidationProvider rules="required|min:3" v-slot="{ errors }">
                        <input 
                            name="project_url"
                            id="project_url"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('settings.placeholder.project_url')"
                            v-model="settings.project_url"
                            >                
                            <span class="text-danger">{{ errors[0] }}</span>  
                        </ValidationProvider>    
                    </div>
                </div>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  components: {   
  },
  data() {
    return {
        settings: {
            project_name: null,
            project_url: null,
        }     
    }
  },
  beforeMount() {
    this.getBasicSettings();
  },
  metaInfo () { 
      return { title: this.$t("settings.meta.subtitle.basic")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("settings.meta.title"), subtitle: this.$t("settings.meta.subtitle.basic") }]);
  },
  methods: {
    getBasicSettings() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/settings/basic")
        .then((response) => {
          this.settings = response.data;
          this.loading = false;
        });
    },
    saveBasicSettings(){
      // Loading spinner
      const submitButton = this.$refs["save_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.loading = true;
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          submitButton.classList.remove("spinner","spinner-light","spinner-right");
          return;
        } 
        ApiService.apiPost("/settings/basic", 
        { 
          'project_name': this.settings.project_name,
          'project_url':  this.settings.project_url,
        })
        .then(() => {
            this.loading = false;
            submitButton.classList.remove("spinner","spinner-light","spinner-right");
        }).catch(error => {
            checkErrors(error);
        });
    });
    }
  }
};
</script>
